class WinFormsObjectIdentity {
    public objectInstanceId: string;
    public objectName: string = '';

    constructor(objectInstanceId: string, objectName: string) {
      this.objectInstanceId = objectInstanceId;
      this.objectName = objectName;
    }
  }

export default WinFormsObjectIdentity
