export const DIALOG_SELECTORS  = {          // using from enum in thinkfolio - AppEventType
    EDIT_TRADE: 2,
    SEARCH_HOLDINGS_GRID_TRANSACTION: 65,
    SEARCH_SECURITIES_GRID_EDIT: 66,
    SEARCH_SECURITIES_GRID_TRADE: 67,
    SEARCH_HOLDINGS_GRID_TRADE: 68
  };

export const APP_EVENT_TYPE = {             // using from enum in thinkfolio - DialogSelectors
    OPEN_DIALOG: 3
}

export const APP_EVENT_TYPE_KEY = {
    SYSTEM_NOTIFICATION: 'systemNotification'
}

export const APP_ENVIRONMENT={              //using keys from .env file
    NODE_ENV: getEnvValueByKey('NODE_ENV'),
    REACT_APP_ENV: getEnvValueByKey('REACT_APP_ENV'),
    REACT_APP_MOCK_SERVER: getEnvValueByKey('REACT_APP_MOCK_SERVER'),
    REACT_APP_BASE_URL: getEnvValueByKey('REACT_APP_BASE_URL'),
    REACT_APP_BASE_API_URL: getEnvValueByKey('REACT_APP_BASE_API_URL'),
    REACT_APP_BASE_DIRECTORY: getEnvValueByKey('REACT_APP_BASE_DIRECTORY'),
    SAM_CLIENT_ID: getEnvValueByKey('SAM_CLIENT_ID'),
    SAM_STS_AUTHORITY: getEnvValueByKey('SAM_STS_AUTHORITY'),
    SAM_API_ROOT: getEnvValueByKey('SAM_API_ROOT'),
    SAM_ENABLED: getEnvValueByKey('SAM_ENABLED'),
    REACT_APP_VERSION_NUMBER: getEnvValueByKey('REACT_APP_VERSION_NUMBER'),
    REACT_APP_API_GATEWAY_TIMEOUT: getEnvValueByKey('REACT_APP_API_GATEWAY_TIMEOUT')
}

function getEnvValueByKey(key: string){ //used to fetch value for a particular key from .env file
   return process.env[key]?process.env[key]:'';
}
