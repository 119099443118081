import WinFormsCommunicationService from 'infrastructure/cefsharp/communication/WinFormsCommunicationService';
import AppMessage from 'infrastructure/cefsharp/entities/AppMessage';
import KeyValuePair from 'types/KeyValuePair';
import HostingEnvironmentService from './HostingEnvironmentService';
import WebAppCommunicationService from 'infrastructure/cefsharp/communication/WebAppCommunicationService';

let communicationService: WinFormsCommunicationService;
let webAppCommunicationService: any;

const publishAction = (payload: KeyValuePair[], eventType: number): void => {
    if (!communicationService) {
        initializeService();
    }
    if(!HostingEnvironmentService.isOpenFin) {
        let appMessage: AppMessage = communicationService.createWinformAppMessage(payload, eventType);
        communicationService.publishMessage(appMessage);
     }
}

const initializeService = () => {
    if(!HostingEnvironmentService.isOpenFin) {
        communicationService = new WinFormsCommunicationService();
        webAppCommunicationService = new WebAppCommunicationService();
        window['WebApplicationBridge' as any] = webAppCommunicationService;
    }
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    publishAction,
    initializeService
};
