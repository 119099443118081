import React, {useEffect, useState} from 'react';
import { FaSpinner } from 'react-icons/fa';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppConfigService } from "./infrastructure/services/AppConfigService";
import { AppConfigEntity } from "./types/AppConfigEntity";
import AppConfigContext from 'infrastructure/contexts/AppConfigContext';
import AppRoutes from "./App.routes";
import PublishActionService from 'services/PublishActionService';
import HostingEnvironmentService from 'services/HostingEnvironmentService';
import { LicenseManager } from 'ag-grid-enterprise';

const queryClient = new QueryClient();

function App() {
  const [isLoaded, setIsLoaded] = useState<boolean | null>();
  const [appConfig, setAppConfig] = useState<AppConfigEntity | null>();
  const loadAppConfig = async () => {
    if (isLoaded === undefined || isLoaded === null) {
      try {
        const appConfigService = new AppConfigService();
        const appConfig = await appConfigService.getAppConfig();
        setIsLoaded(true);
        setAppConfig(appConfig);
        setAgGridLicenseKey(appConfig)
      }
      catch {
        setIsLoaded(false);
      }
    }
  };
  
  function setAgGridLicenseKey(appConfigObj: AppConfigEntity | undefined){
    const agGridLicenseKey = appConfigObj ? appConfigObj.environmentSettings.agGridLicenseKey : '';  
    LicenseManager.setLicenseKey(agGridLicenseKey);
  }

  useEffect(() => {
    (async () => {
      await loadAppConfig();
    })();
  });

  if(!HostingEnvironmentService.isOpenFin) {
    PublishActionService.initializeService();
  }

  if (isLoaded === undefined || isLoaded === null) {
    return (
        <div className="w-screen h-screen flex items-center justify-center">
          <FaSpinner className="ml-3 animate-spin text-white text-3xl"/>
        </div>
    );
  } else if (isLoaded && appConfig) {
    return (
        <React.Suspense
            fallback={
              <div className="w-screen h-screen flex items-center justify-center">
                <FaSpinner className="ml-3 animate-spin text-white text-3xl"/>
              </div>
            }>
          <AppConfigContext.Provider value={appConfig}>
            <QueryClientProvider client={queryClient}>
               <AppRoutes></AppRoutes>
            </QueryClientProvider>
          </AppConfigContext.Provider>
        </React.Suspense>
    );
  } else {
    return (
        <div className="w-screen h-screen flex items-center justify-center">
          <h3 className="ml-3 text-white text-2xl">Problem with loading the configuration file. Please contact the administrator for support.</h3>
        </div>
    );
  }
}

export default App;
