import { AppConfigEntity } from '../../types/AppConfigEntity';

export class AppConfigService {
    private _appConfigUrl: string = './config.json';
    private _appConfig: AppConfigEntity | undefined;

    public async getAppConfig(): Promise<AppConfigEntity | undefined> {
        if (!this._appConfig) {
            try {
                this._appConfig = await (await fetch(this._appConfigUrl)).json();
                console.log('Config');
                console.log(this._appConfig);
                return this._appConfig;
            } catch (e) {
                throw new Error('Unable to load config file.');
            }
        }
        else {
            return this._appConfig;
        }
    }
}
