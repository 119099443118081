import { TokenService } from 'services/TokenService';
import KeyValuePair from 'types/KeyValuePair';
import AppMessage from '../entities/AppMessage';
import { APP_EVENT_TYPE_KEY } from 'constants/Constants';

class WebAppCommunicationService {
    publishMessage(appMessage: AppMessage) {
       if ((appMessage.eventSourceHostType === null) || (appMessage.eventSourceHostType === undefined)
          || (appMessage.eventType === null) || (appMessage.eventType === undefined)
          || appMessage.eventTargetType === null
          || appMessage.eventTargetType === undefined) {
            return Promise.reject('Invalid app message');
          }

       switch(appMessage.eventType.toString()) {
           case APP_EVENT_TYPE_KEY.SYSTEM_NOTIFICATION: let token = this.getPayloadValue(appMessage.payload, 'authToken');
                    TokenService.setToken(token); break;
       }
       return Promise.resolve();
      }

      getPayloadValue(payload: KeyValuePair[], key: string): any {
        const value = payload.filter(p => p.key === key).map(kvp => kvp.value);
        return value[0];
      }

}

export default WebAppCommunicationService;