import { AppConfigService } from 'infrastructure/services/AppConfigService';
import { AppConfigEntity } from 'types/AppConfigEntity';
import HostingEnvironmentService from './HostingEnvironmentService';

const appConfigService = new AppConfigService();
let appConfig: AppConfigEntity | undefined;
let key: string;

const setKey = async () => {
    appConfig = await appConfigService.getAppConfig();
    const baseKey: string = appConfig?.environmentSettings.directoryName || '';
    key = `${baseKey}${(HostingEnvironmentService.isOpenFin ? 'access_token' : 'token')}`;
}
const setToken = async (token: string) => {
    if(!key) {
        await setKey();
    }
    if(HostingEnvironmentService.isOpenFin) {
        window.localStorage.setItem(key, token);
    } else {
        window.sessionStorage.setItem(key, token);
    }
}

const getToken = async () => {
    if(!key) {
        await setKey();
    }
    let token: string | null;
    if(HostingEnvironmentService.isOpenFin) {
        token = window.localStorage.getItem(key);
    } else {
        token = window.sessionStorage.getItem(key);
    }
    return token;
}

const clearToken = async () => {
    if(!key) {
        await setKey();
    }
    if(HostingEnvironmentService.isOpenFin) {
        window.localStorage.removeItem(key);
    } else {
        window.sessionStorage.removeItem(key);
    }
}

export const TokenService = {
    setToken,
    getToken,
    clearToken
}