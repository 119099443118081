import AppEventSourceHostType from '../enums/AppEventSourceHostType';
import AppEventTargetType from '../enums/AppEventTargetType';
import KeyValuePair from '../../../types/KeyValuePair';
import WinFormsObjectIdentity from './WinFormsObjectIdentity';

class AppMessage {
    public eventSourceHostType: AppEventSourceHostType;
    public eventTargetType: AppEventTargetType;
    public eventType: number;
    public payload: KeyValuePair[];
    public winFormsObjectIdentity: WinFormsObjectIdentity;

    constructor(eventSourceHostType: AppEventSourceHostType, eventTargetType: AppEventTargetType,
       eventType: number, payload: KeyValuePair[], winFormsObjectIdentity: WinFormsObjectIdentity) {
        this.eventSourceHostType = eventSourceHostType;
        this.eventTargetType = eventTargetType;
        this.eventType = eventType;
        this.payload = payload;
        this.winFormsObjectIdentity = winFormsObjectIdentity;
    }
  }

export default AppMessage;