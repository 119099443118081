import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import React from "react";
import AppConfigContext from "./infrastructure/contexts/AppConfigContext";
const Grid = React.lazy(() => import('./modules/Grid/Grid'));
const PortfolioHistoricAreaChart = React.lazy(() => import('./modules/PortfolioHistoricAreaChart'));
const PortfolioAreaChart = React.lazy(() => import('./modules/PortfolioAreaChart'));
const PortfolioPieChart = React.lazy(() => import('./modules/PortfolioPieChart'));
const DataAccessTest = React.lazy(() => import('./modules/DataAccessTest/DataAccessTest'));
const WindowsManager = React.lazy(() => import('./modules/WindowsManager/WindowsManager'));
const Launcher = React.lazy(() => import('./modules/Launcher'));
const Alerts = React.lazy(() => import('./modules/Alerts'));
const Checklist = React.lazy(() => import('./modules/Checklist'));
const Workspaces = React.lazy(() => import('modules/Workspaces/Workspaces'));
const Profile = React.lazy(() => import('./modules/Profile'));
const Settings = React.lazy(() => import('./modules/Settings'));
const Help = React.lazy(() => import('./modules/Help'));
const SummaryGrid = React.lazy(() => import('./modules/SummaryGrid'));
const SummaryHoldingsGrid = React.lazy(() => import('./modules/SummaryHoldingsGrid'));
const Notifications = React.lazy(() => import('./modules/Notifications'));
const TradingView = React.lazy(() => import('./modules/TradingView'));
const FintechNews = React.lazy(() => import('./modules/FintechNews'));
const FintechStudiosQuote = React.lazy(() => import('./modules/FintechStudiosQuote'));
const BondCliqBIPMWidget = React.lazy(() => import('./modules/BondCliqBIPMWidget'));
const SmartQuoteWidget = React.lazy(() => import('./modules/SmartQuoteWidget'));
const IHSMParsingData = React.lazy(() => import('./modules/IHSMParsingData/IHSMParsingData'));
const WidgetSettings = React.lazy(() => import('./modules/WidgetSettings'));
const ComplianceBreach = React.lazy(() => import('./modules/ComplianceBreach'));
const StrategyMonitorGrid = React.lazy(() => import('./modules/StrategyMonitorGrid'));
const PortfolioSelector = React.lazy(() => import('./modules/PortfolioSelector'));
const LumeAlfaWidget = React.lazy(() => import('./modules/LumeAlfaWidget'));
const TopAndBottomRiskContribution = React.lazy(() => import('./modules/TopAndBottomRiskContribution'));
const Search = React.lazy(() => import('./modules/Search'));

const AppRoutes = () => {
    const appConfig = React.useContext(AppConfigContext);
    const baseDir =  appConfig ? appConfig.environmentSettings.directoryName : '';
    return (
        <Router basename={baseDir}>
            <div>
                <Switch>
                    <Route exact path="/historic-area"><PortfolioHistoricAreaChart/></Route>
                    <Route exact path="/portfolio-area"><PortfolioAreaChart/></Route>
                    <Route exact path="/portfolio-pie"><PortfolioPieChart/></Route>
                    <Route exact path="/grid"><Grid/></Route>
                    <Route exact path="/launcher"><Launcher/></Route>
                    <Route exact path="/alerts"><Alerts/></Route>
                    <Route exact path="/checklist"><Checklist/></Route>
                    <Route exact path="/workspace-manager"><Workspaces/></Route>
                    <Route exact path="/profile"><Profile/></Route>
                    <Route exact path="/settings"><Settings/></Route>
                    <Route exact path="/help"><Help/></Route>
                    <Route exact path="/data-access-test"><DataAccessTest/></Route>
                    <Route exact path="/summary"><SummaryGrid/></Route>
                    <Route exact path="/notifications"><Notifications/></Route>
                    <Route exact path="/summary-holdings"><SummaryHoldingsGrid/></Route>
                    <Route exact path="/trading-view"><TradingView/></Route>
                    <Route exact path="/fintech-news"><FintechNews/></Route>
                    <Route exact path="/fintech-studios-quote"><FintechStudiosQuote/></Route>
                    <Route exact path="/bondcliq-bipm"><BondCliqBIPMWidget/></Route>
                    <Route exact path="/lumealfa"><LumeAlfaWidget/></Route>
                    <Route exact path="/smart-quote-widget"><SmartQuoteWidget/></Route>
                    <Route exact path="/ihsm-parsing-data"><IHSMParsingData/></Route>
                    <Route exact path="/widget-settings"><WidgetSettings/></Route>
                    <Route exact path="/compliance-breach"><ComplianceBreach/></Route>
                    <Route exact path="/strategy-monitor"><StrategyMonitorGrid/></Route>
                    <Route exact path="/portfolio-selector"><PortfolioSelector/></Route>
                    <Route exact path="/search"><Search/></Route>
                    <Route exact path="/risk-contribution"><TopAndBottomRiskContribution/></Route>
                    <Route exact path="/"><WindowsManager/></Route>
                </Switch>
            </div>
        </Router>
    )
};

export default AppRoutes;
