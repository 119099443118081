import AppMessage from '../entities/AppMessage';
import KeyValuePair from '../../../types/KeyValuePair';
import WinFormsObjectIdentity from '../entities/WinFormsObjectIdentity';
import AppEventSourceHostType from '../enums/AppEventSourceHostType';
import AppEventTargetType from '../enums/AppEventTargetType';

class WinFormsCommunicationService {
    private _windowsAppCommunicationService: any;

    constructor() {
        if (window['WindowsAppCommunicationService' as any] === undefined) {
            (async function () {
              if (window['CefSharp' as any] !== undefined && window['CefSharp' as any] !== null) {
                await (window['CefSharp' as any] as any).BindObjectAsync('WindowsAppCommunicationService');
              }
            })();
          }
    }

    public checkWindowsAppCommunicationServiceExists(): boolean {
        if (!this._windowsAppCommunicationService && window['WindowsAppCommunicationService' as any] !== undefined) {
          this._windowsAppCommunicationService = window['WindowsAppCommunicationService' as any];
         ( window['WinFormsApplicationBridge' as any] as any) = this;
        }

        if (this._windowsAppCommunicationService) {
          return true;
        }
        else {
          return false;
        }
      }

      public publishMessage(appMessage: AppMessage): Promise<any> {
        if ((appMessage.eventSourceHostType === null) || (appMessage.eventSourceHostType === undefined)
          || (appMessage.eventType === null) || (appMessage.eventType === undefined)
          || appMessage.eventTargetType === null
          || appMessage.eventTargetType === undefined) {
          return Promise.reject('Invalid appMessage.');
        }

        if (this.checkWindowsAppCommunicationServiceExists()) {
          return Promise.resolve(this._windowsAppCommunicationService.publishMessage(JSON.stringify(appMessage)));
        }
        else {
          return Promise.reject('The windowsAppCommunicationService is not defined');
        }
      }

      public createWinformAppMessage(payload: KeyValuePair[], eventType: number): AppMessage {
        let winFormsObjectIdentity = new WinFormsObjectIdentity(this.getWindowInstanceId(), '');
        let appMessage: AppMessage = new AppMessage(AppEventSourceHostType.webApp, AppEventTargetType.wholeApplication, eventType, payload, winFormsObjectIdentity);

        return appMessage;
      }

      public getWindowInstanceId(): string {
        const urlParams = new URLSearchParams(window.location.search);
        const windowInstanceId: string = urlParams.get('windowInstanceId') || '';
        console.log('window instance id: ', windowInstanceId)
        return windowInstanceId;
      }

}

export default WinFormsCommunicationService;